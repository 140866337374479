<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.email"
                  :label="$t('users.Email')"
                  name="email"
                  prepend-icon="email"
                  type="text"
                  :disabled="sending || editedItem !== null"
                  :rules="rules.email"
                  @input="resetInputError('email')"
                ></v-text-field>

                <v-text-field
                  v-model="form.first_name"
                  prepend-icon="person"
                  :label="$t('users.FirstName')"
                  :rules="rules.name"
                  @input="resetInputError('first_name')"
                ></v-text-field>

                <v-text-field
                  v-model="form.last_name"
                  prepend-icon="person"
                  :label="$t('users.LastName')"
                  :rules="rules.name"
                  @input="resetInputError('last_name')"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('users.Password')"
                  name="password"
                  prepend-icon="lock"
                  :type="showPass ? 'text' : 'password'"
                  counter
                  :rules="rules.password"
                  :disabled="sending"
                  @click:append="showPass = !showPass"
                  @input="resetInputError('password')"
                />
                <v-alert
                  v-if="editedItem !== null"
                  text
                  dense
                  type="info"
                  class="mt-5"
                  >{{ $t("users.leave empty") }}</v-alert
                >
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="form.roles"
                  :items="forSelect(data.can_roles)"
                  item-text="text"
                  item-value="id"
                  chips
                  multiple
                  :label="$t('users.Roles')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      :class="
                        item.id === 1 ? 'red' : item.id === 2 ? 'orange' : ''
                      "
                      >{{ item.text }}</v-chip
                    >
                  </template>
                </v-select>

                <v-select
                  v-model="form.permissions"
                  :items="forSelect(data.can_permissions)"
                  item-text="text"
                  item-value="id"
                  chips
                  multiple
                  :label="$t('users.Direct permissions')"
                ></v-select>

                <v-select
                  v-if="data.clients"
                  v-model="form.clients"
                  :items="forSelect(data.clients)"
                  item-text="text"
                  item-value="id"
                  chips
                  multiple
                  :label="$t('users.Clients')"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("users.User saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/users/",
      showPass: false,
      defaultItem: {
        name: "",
        email: "",
        password: "",
      },
      rules: {
        first_name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("users.FirstName"),
            }),
          () =>
            this.getInputError("first_name", {
              min: 2,
              name: this.$t("users.FirstName"),
            }),
        ],
        last_name: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("users.LastName") }),
          () =>
            this.getInputError("last_name", {
              min: 2,
              name: this.$t("users.LastName"),
            }),
        ],
        password: [
          (v) =>
            this.editedItem === null
              ? !!v ||
                this.$t("validation.required", {
                  name: this.$t("users.Password"),
                })
              : true,
          (v) =>
            v
              ? v.length >= 8 || this.$t("validation.min.string", { min: 8 })
              : true,
          () =>
            this.getInputError("password", {
              min: 8,
              name: this.$t("users.Password"),
            }),
        ],
        email: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("users.Email") }),
          (v) => {
            return validate.email.test(v) || this.$t("users.Invalid e-mail");
          },
          () =>
            this.getInputError("email", {
              name: this.$t("users.Email"),
            }),
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("users.Add user")
        : this.$t("users.Edit user");
    },
  },

  methods: {},
};
</script>
