var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('base-panel',{attrs:{"icon":"mdi-account-multiple","title":_vm.$t('users.page title')}},[_c('users-form',{ref:"editForm",attrs:{"data":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('users.Delete user')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v("Are you sure you want to delete account of "+_vm._s(item.name)+"?")]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v("Account of "+_vm._s(item.name)+" deleted")]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading || !_vm.meta,"multi-sort":"","footer-props":{
        'items-per-page-options': [10, 20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("users.Add user"))+" ")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.roles",fn:function(ref){
      var item = ref.item;
return [(_vm.meta && _vm.meta.roles)?_c('span',_vm._l((item.roles),function(role){return _c('v-chip',{key:'role' + role,class:role === 1 ? 'red' : role === 2 ? 'orange' : '',attrs:{"small":""}},[_vm._v(_vm._s(_vm.meta.roles[role]))])}),1):_vm._e()]}},{key:"item.clients",fn:function(ref){
      var item = ref.item;
return [(_vm.meta && _vm.meta.clients)?_c('span',_vm._l((item.clients),function(cl){return _c('v-chip',{key:'client' + cl,attrs:{"small":""}},[_vm._v(_vm._s(_vm.meta.clients[cl]))])}),1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }